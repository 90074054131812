import "./src/styles/global.css";
import "@fontsource/poppins";
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lft9_sgAAAAAL-qfmc3a88LzKix32yrsYBMSUBz">
            {element}
        </GoogleReCaptchaProvider>
    )
}